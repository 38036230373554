<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Vamos começar preenchendo as informações básicas
    </div>
    <v-row id="importacao-titulo" class="mx-auto">
      <v-col cols="12">
        <validation-provider v-slot="{ errors }" rules="required">
          <v-text-field
            id="fs-titulo-importacao-dados"
            label="Título da importação"
            outlined
            :error-messages="errors"
            v-model="titulo"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row id="importacao-descricao" class="mx-auto">
      <v-col cols="12">
        <v-text-field
          id="fs-descricao-importacao-dados"
          label="Descrição (opcional)"
          outlined
          v-model="descricao"
        />
      </v-col>
    </v-row>
    <v-row id="importacao-empresa" class="mx-auto">
      <v-col cols="12">
        <select-company
          id="fs-empresa-importacao-dados"
          :type="'outlined'"
          @companySelected="companySelected"
        />
      </v-col>
    </v-row>
    <v-row id="importacao-tipo" class="mx-auto">
      <v-col cols="12">
        <select-tipos-importacoes-dados
          id="fs-tipo-importacao-dados"
          :type="'outlined'"
          :companyId="companyId"
          @tipoImportacaoSelecionado="tipoSelected"
        />
      </v-col>
    </v-row>  
    <v-row 
      id="importacao-mes" 
      class="mx-auto"
      v-if="dataRegistroObrigatorio"
    >
      <v-col cols="12" id="fs-mes-dados-importacao-dados">
        <input-month
          label="Mês dos dados"
          @monthSelected="monthSelected"
        />
      </v-col>
    </v-row>

    <v-row class="mx-auto" v-if="dataRegistroObrigatorio">
      <v-col cols="12">
        <validation-provider v-slot="{ errors }" rules="required|min_value:0">
          <v-text-field
            class="my-0"
            type="number"
            min="0"
            label="Versão do INDGER"
            v-model="indgerVersion"
            :value="indgerVersion"
            prepend-icon="mdi-numeric-1-box-multiple-outline"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" v-if="mes">
        <v-alert
          dense 
          outlined 
          type="info" 
          class="mb-1 py-1 px-1"
        >
          Última versão de {{ data | parseToCustomDateFormat('MM/YYYY') }}: <strong> {{ lastIndgerVersion >= 0 ? `v${lastIndgerVersion}` : "nenhuma versão cadastrada" }} </strong>
        </v-alert>
        <v-alert
          dense 
          outlined 
          type="info" 
          class="mb-1 py-1 px-1"
        >
          <strong>Obs.:</strong> as validações de indicadores são executadas apenas na <strong>versão 0</strong> (versão de trabalho)
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import IndgerVersionsService from "@/services/IndgerVersionsService.js";

export default {
  name: 'FirstTabContent',
  components: {
    SelectCompany: () => import('@/components/general/SelectCompany.vue'),
    SelectTiposImportacoesDados: () => import('@/components/general/SelectTiposImportacoesDados.vue'),
    InputMonth: () => import('@/components/general/InputMonth.vue'),
  },
  data() {
    return {
      titulo: null,
      descricao: null,
      companyId: null,
      tipoImportacao: null,
      mes: null,
      indgerVersion: 0,
      lastIndgerVersion: null,
    }
  },
  computed: {
    data() {
      return this.mes ? `${this.mes}-01` : null;
    },
    dataRegistroObrigatorio() {
      return this.tipoImportacao && this.tipoImportacao.data_registro_obrigatorio;
    },
    arquivoModeloImportacao() {
      return this.tipoImportacao?.arquivo_modelo_importacao;
    }
  },
  watch: {
    titulo() {
      this.emitEventImportUpdated();   
    },
    descricao() {
      this.emitEventImportUpdated();   
    },
    companyId() {
      this.emitEventImportUpdated();   
      this.setLastIndgerVersion(this.mes);
    },
    indgerVersion() {
      this.emitEventImportUpdated();   
    },
    tipoImportacao() {
      this.emitEventImportUpdated();   
    },  
    mes() {
      this.emitEventImportUpdated();   
      this.setLastIndgerVersion(this.mes);
    },
  },
  methods: {
    tipoSelected(tipoImportacao) {
      this.tipoImportacao = tipoImportacao;
    },
    companySelected(company) {
      this.companyId = company.id;
    },
    monthSelected(month) {
      this.mes = month;
    },
    setLastIndgerVersion(dataMonth) {
      if (!dataMonth) {
        this.lastIndgerVersion = null;
        this.indgerVersion = 1;
        return;
      }

      IndgerVersionsService.getLastVersionByCompanyAndDataMonth(
        this.companyId,
        dataMonth
      )
        .then(res => {
          this.lastIndgerVersion = res?.version;
          this.indgerVersion = res?.version ? res.version + 1 : 1;
        })
        .catch(err => {
          console.error(err);
        });
    },
    emitEventImportUpdated() {
      this.$emit('import:updated', {
        titulo: this.titulo,
        descricao: this.descricao,
        companyId: this.companyId,
        indgerVersion: this.indgerVersion,
        tipoImportacao: this.tipoImportacao,
        mes: this.mes,
        data: this.data,
      });  
    } 
  } 
};

</script>
